<!--
 * @FilePath: /zhzg_pc/src/views/GIS/grid_ljzz.vue
 * @Date: 2022-04-15 17:19:37
 * @LastEditTime: 2022-04-15 17:34:54
 * @Description: 垃圾中转站
 * @LastEditors: xz
-->
<template>
  <div class="wrapper">
    <div class="baidu-map-echart" id="container"></div>
    <div class="operateWrapper">
      <el-input
        v-model="searchAdress.text"
        size="large"
        placeholder="查询地址"
      />
      <el-button class="searchBtn" @click="searchAction">Search</el-button>
    </div>
  </div>
</template>

<script>
import village from "@/assets/mjimg/xiaoqu.png";
import BMap from "BMap";
import recycle from "@/assets/mjimg/recycle.png";
import { ref, onMounted, reactive } from "vue";
export default {
  setup() {
    const searchAdress = reactive({
      text: "",
    });
    //标点的数据格式，其中value是必须的[经度，纬度，值]
    const state = reactive({
      // 站点
      community: [
        {
          name: "广安东里社区站点",
          cord: [116.37908, 39.89725],
          states: "已投满",
          volume: ["广安东里社区", "李强"],
          img: "https://img0.baidu.com/it/u=1859295068,2806768670&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500",
        },
        {
          name: "康乐里社区站点",
          cord: [116.37398, 39.90157],
          states: "正常",
          volume: ["康乐里社区", "刘磊"],
          img: "http://t15.baidu.com/it/u=2155542720,2974011896&fm=224&app=112&f=JPEG?w=500&h=500",
        },
        {
          name: "富国里小区站点",
          cord: [116.37221, 39.93584],
          states: "已投满",
          volume: ["富国里小区", "李强"],
          img: "https://img0.baidu.com/it/u=1859295068,2806768670&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500",
        },
        {
          name: "六铺炕小区站点",
          cord: [116.3929, 39.96057],
          states: "正常",
          volume: ["六铺炕小区", "刘磊"],
          img: "http://t15.baidu.com/it/u=2155542720,2974011896&fm=224&app=112&f=JPEG?w=500&h=500",
        },
        {
          name: "北海公园站点",
          cord: [116.39536, 39.93314],
          states: "已投满",
          volume: ["北海公园", "李强"],
          img: "https://img0.baidu.com/it/u=1859295068,2806768670&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500",
        },
        {
          name: "什刹海公园站点",
          cord: [116.39169, 39.94828],
          states: "已投满",
          volume: ["什刹海公园", "李强"],
          img: "https://img0.baidu.com/it/u=1859295068,2806768670&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500",
        },
        {
          name: "北京动物园站点",
          cord: [116.34329, 39.94711],
          states: "正常",
          volume: ["北京动物园", "刘磊"],
          img: "http://t15.baidu.com/it/u=2155542720,2974011896&fm=224&app=112&f=JPEG?w=500&h=500",
        },
      ],
      //   中转站
      transfer: [
        {
          name: "平安里中转站",
          cord: [116.37461, 39.93943],
          volume: ["LJZZ0001", "使用中 80%", "王城"], // 已处理、待处理、容积
        },
        {
          name: "复兴门中转站",
          cord: [116.36445, 39.91304],
          volume: ["LJZZ0002", "使用中 20%", "王城"],
        },
        {
          name: "广安门中转站",
          cord: [116.35956, 39.8956],
          volume: ["LJZZ0003", "已满 100%", "李强"],
        },
      ],
      //   处理厂
      handleC: [
        {
          name: "西城区垃圾处理厂",
          cord: [116.35213 , 39.92004],
        },
      ],
    });
    // 自定义地图样式
    // const mapStyle = {};
    // let timer = ref(0);
    // 初始化地图
    let mapObj = new AMap.Map('iCenter');
    mapObj.plugin('AMap.Geolocation', function () {
        geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,//是否使用高精度定位，默认:true
            timeout: 10000,          //超过10秒后停止定位，默认：无穷大
            maximumAge: 0,           //定位结果缓存0毫秒，默认：0
            convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
            showButton: true,        //显示定位按钮，默认：true
            buttonPosition: 'LB',    //定位按钮停靠位置，默认：'LB'，左下角
            buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
            showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
            panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
            zoomToAccuracy:true      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        mapObj.addControl(geolocation);
        geolocation.getCurrentPosition(); //精准定位
        geolocation.getCityInfo();  //定位到城市
        console.log(geolocation.getCityInfo(),'-----------------');
        AMap.event.addListener(geolocation, 'complete', onComplete);//返回定位信息
        AMap.event.addListener(geolocation, 'error', onError);      //返回定位出错信息
    });
    // // 绘制标识物 -- 站点
    // function addCommunityMarker() {
    //   // const icon = new BMap.Icon(village, new BMap.Size(30, 30));
    //   state.community.forEach((item) => {
    //     // 标识物
    //     const marker = new BMap.Marker(
    //       new BMap.Point(item.cord[0], item.cord[1])
    //     );
    //     map.addOverlay(marker);
    //     // 标注
    //     const opts = {
    //       position: new BMap.Point(item.cord[0], item.cord[1]), // 指定文本标注所在的地理位置
    //       offset: new BMap.Size(0, 0), // 设置文本偏移量
    //     };
    //     const label = new BMap.Label(item.name, opts); // 创建文本标注对象
    //     label.setStyle({
    //       lineHeight: "20px",
    //       borderColor: "#ccc",
    //       padding: "0 4px",
    //     });
    //     map.addOverlay(label);

        // 信息弹窗
        const content = `
        <div
            style="
              width: 280px;
              height: 380px;
              border: 5px solid yellow;
              border-radius: 20px;
              background-color: #fff;
              position: relative;
              overflow: hidden;
            "
          >
            
            <span style="display:block; background-color:rgb(0,153,255); height:40px; line-height:40px; font-size:18px; text-align:center; color:#fff">${item.name}</span>
            <div 
              style="
                margin: 15px 30px;
              "
            >
              <div
                style="
                  margin-top: 15px;
                  color: #333;
                  line-height: 20px;
                  font-weight: 600;
                "
              >
               <img/>
                  
                  <img style='margin-left:0; width:100%;' id='imgDemo' src='${item.img}' height='204'/>
                <span>所属社区：${item.volume[0]}</span>
                <br/>
                <span>环卫人员：${item.volume[1]}</span>
                <br/>
                <span>当前状态：${item.states}</span>
              </div>
              </div>
            </div>
        `;
      //   const infoWindow = new BMap.InfoWindow(content, {
      //     // 创建信息窗口对象
      //     width: 280, // 信息窗口宽度
      //     height: 380, // 信息窗口高度
      //     title: item.name, // 信息窗口标题
      //   });
      //   marker.addEventListener("mouseover", function () {
      //     //开启信息窗口
      //     map.openInfoWindow(infoWindow, opts.position);
      //   });
      //   marker.addEventListener("mouseout", function () {
      //     //关闭信息窗口
      //     map.closeInfoWindow();
      //   });
      // });
    // }
    // 绘制标识物 -- 中转站
    // function addTransferMarker() {
    //   const transIcon = new BMap.Icon(recycle, new BMap.Size(30, 30));
    //   state.transfer.forEach((item) => {
    //     // 标识物
    //     const marker = new BMap.Marker(
    //       new BMap.Point(item.cord[0], item.cord[1]),
    //       { icon: transIcon }
    //     );
    //     map.addOverlay(marker);
    //     // 标注
    //     const opts = {
    //       position: new BMap.Point(item.cord[0], item.cord[1]), // 指定文本标注所在的地理位置
    //       offset: new BMap.Size(20, -10), // 设置文本偏移量
    //     };
    //     const label = new BMap.Label(item.name, opts); // 创建文本标注对象
    //     label.setStyle({
    //       lineHeight: "20px",
    //       borderColor: "#ccc",
    //       padding: "0 4px",
    //     });
    //     map.addOverlay(label);

    //     // 信息弹窗
    //     const content = `
    //     <div
    //         style="
    //           width: 280px;
    //           height: 200px;
    //           border: 5px solid yellow;
    //           border-radius: 20px;
    //           background-color: #fff;
    //           position: relative;
    //           overflow: hidden;
    //         "
    //       >
            
    //         <span style="display:block; background-color:rgb(0,153,255); height:40px; line-height:40px; font-size:18px; text-align:center; color:#fff">${item.name}</span>
    //         <div 
    //           style="
    //             margin: 15px 30px;
    //           "
    //         >
    //           <div
    //             style="
    //               margin-top: 15px;
    //               color: #333;
    //               line-height: 20px;
    //               font-weight: 600;
    //             "
    //           >
    //             <span>编号：${item.volume[0]}</span>
    //             <br/>
    //             <span>溢满情况：${item.volume[1]}</span>
    //             <br/>
    //             <span>责任人：${item.volume[2]}</span>
    //           </div>
    //           </div>
    //         </div>
    //     `;
    //     const infoWindow = new BMap.InfoWindow(content, {
    //       // 创建信息窗口对象
    //       width: 280, // 信息窗口宽度
    //       height: 200, // 信息窗口高度
    //       title: item.name, // 信息窗口标题
    //     });
    //     marker.addEventListener("mouseover", function () {
    //       //开启信息窗口
    //       map.openInfoWindow(infoWindow, opts.position);
    //     });
    //     marker.addEventListener("mouseout", function () {
    //       //关闭信息窗口
    //       map.closeInfoWindow();
    //     });
    //   });
    // }
    // 绘制标识物 -- 处理厂
    // function addHandleCMarker() {
    //   const icon = new BMap.Icon(village, new BMap.Size(30, 30));
    //   state.handleC.forEach((item) => {
    //     // 标识物
    //     const marker = new BMap.Marker(
    //       new BMap.Point(item.cord[0], item.cord[1]),
    //       { icon: icon }
    //     );
    //     map.addOverlay(marker);
    //     // 标注
    //     const opts = {
    //       position: new BMap.Point(item.cord[0], item.cord[1]), // 指定文本标注所在的地理位置
    //       offset: new BMap.Size(0, 0), // 设置文本偏移量
    //     };
    //     const label = new BMap.Label(item.name, opts); // 创建文本标注对象
    //     label.setStyle({
    //       lineHeight: "20px",
    //       borderColor: "#ccc",
    //       padding: "0 4px",
    //     });
    //     map.addOverlay(label);
    //   });
    // }
    // // 中文搜索定位
    // function searchAction() {
    //   if (searchAdress.text === "") return;
    //   const addressName = `${searchAdress.text}`;
    //   // map.centerAndZoom(new BMap.Point(112.45143, 34.83224), 11);
    //   const local = new BMap.LocalSearch(map, {
    //     renderOptions: {
    //       map: map,
    //     },
    //   });

    //   local.search(addressName);

    //   searchAdress.text = "";
    //   // 点击地图时关闭信息窗口
    //   // map.enableCloseOnClick()
    // }
    // let myGeo = new BMapGL.Geocoder();
    onMounted(() => {
      initMapGL();
      addCommunityMarker();
      addTransferMarker();
      addHandleCMarker();
    });
    return {
      // timer,
      // state,
      // searchAdress,
      // searchAction,
    };
  },
};
</script>
<style scoped>
.wrapper {
  height: 100%;
  position: relative;
}
.baidu-map-echart {
  height: 100%;
}
.operateWrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
}
.searchBtn {
  margin-left: 10px;
}
</style>
